import service from '@/middleware'

const realSpeedReportApi = {
  async getRealSpeedReport (things, states, sectorId, from, to, timeFormat, route, filters, greaterThan, lessThan) {
    const body = {
      reportQueryFilters: {
        things,
        states,
        sectorId,
        from,
        to,
        timeFormat,
        route,
        greaterThan,
        lessThan
      },
      filters
    }
    return await service.http.post('/api/real-speed-report/deferred', body)
  },
  async getRealSpeedReportDirect (things, statesId, from, to, sector, timeFormat, activities, types, greaterThan, lessThan) {
    const body = {
      things,
      statesId,
      from,
      to,
      sector,
      timeFormat,
      activities,
      types,
      greaterThan,
      lessThan
    }
    return await service.http.post('/api/real-speed-report/not-deferred', body)
  },
  async getLineChart (things, statesId, from, to) {
    const body = {
      things,
      statesId,
      from,
      to
    }
    return await service.http.post('/api/real-speed-report/line-chart', body)
  }
}

export default realSpeedReportApi
