/* eslint-disable indent */
import realSpeedReportApi from '@/api/realSpeedReport.api'
import {Axes, Chart, ChartType, Dataset, Options, ReportConversionUtil, ValueText} from '@colven/common-domain-lib/lib'
import {getTimeOffset} from '@/tools/functions'
import moment from 'moment'
import i18n from '@/i18n'
import realSpeedConstants from '@/constants/realSpeedReport.constants'
import conversionUtil from '../utils/conversion.util'

// Rangos por defecto para el gráfico de torta
const percentageChartDefaultRanges = [
    {
        intervalStart: 0,
        intervalEnd: 50,
        color: '#FF0000'
    },
    {
        intervalStart: 50,
        intervalEnd: 100,
        color: '#00FF00'
    },
    {
        intervalStart: 100,
        intervalEnd: undefined,
        color: '#0000FF'
    }
]

/**
 * Para obtener el gráfico de porcentaje de tiempo para cosas
 * @param data
 * @param thingIds
 * @param ranges
 * @param timeFormat
 * @param from
 * @param to
 * @param from
 * @param to
 */
const getPercentageChartByThings = (data, thingIds, ranges, timeFormat, from, to) => {
    let thingsData = data.filter(element => thingIds.includes(element.thingId))
    if (thingsData != null && thingsData.length > 0) {
      return getDoughnutChart(thingsData, ranges || percentageChartDefaultRanges, timeFormat.value, from, to)
    }
    return null
}

/**
 * Para obtener el gráfico de porcentaje de tiempo para frentes de trabajo
 * @param {*} data
 * @param {*} workFrontIds
 * @param {*} ranges
 * @param {*} timeFormat
 * @param from
 * @param to
 * @returns
 */
const getPercentageChartByWorkFronts = (data, workFrontIds, ranges, timeFormat, from, to) => {
    let thingsData = data.filter(element => workFrontIds.includes(element.workFrontId))
    if (thingsData != null && thingsData.length > 0) {
      return getDoughnutChart(thingsData, ranges || percentageChartDefaultRanges, timeFormat.value, from, to)
    }
    return null
}

/**
 * Crea el gráfico de dona
 * @param data
 * @param ranges
 * @param timeFormat
 * @param from
 * @param to
 */
const getDoughnutChart = (data, ranges, timeFormat, from, to) => {
    const yAxes = new Axes(ChartType.DOUGHNUT)
    const xAxes = new Axes(ChartType.DOUGHNUT)
    const options = new Options()
    options.tooltips.mode = 'point'
    const chart = new Chart()

    // id
    chart.id = 'realSpeedReportBottomRightChart'
    // tipo
    chart.type = ChartType.DOUGHNUT

    // dataset
    const dataset = new Dataset()
    dataset.backgroundColor = []

    // datos de porcentajes
    const chartData = getPercentageTimeData(data, ranges)

    // labels
    let label = ''
    ranges.forEach((r, index) => {
        if (!r.intervalStart && r.intervalEnd) {
            label = i18n.t('realSpeedReport.CHARTS.LESS_THAN') +
                ' ' + r.intervalEnd + ' ' + i18n.t('realSpeedReport.CHARTS.KMH')
        }
        if (r.intervalStart && !r.intervalEnd) {
            label = i18n.t('realSpeedReport.CHARTS.GREATER_THAN') +
                ' ' + r.intervalStart + ' ' + i18n.t('realSpeedReport.CHARTS.KMH')
        }
        if (r.intervalStart && r.intervalEnd) {
            label = r.intervalStart + ' ' + i18n.t('realSpeedReport.CHARTS.KMH') +
                ' ' + i18n.t('realSpeedReport.CHARTS.TO') + ' ' +
                r.intervalEnd + ' ' + i18n.t('realSpeedReport.CHARTS.KMH')
        }
        const percentage = Math.round((chartData.result[index] * 100 / chartData.total) * 100) / 100
        label += ' (' + (percentage || 0) + '%)'
        chart.data.labels.push(label)
        dataset.backgroundColor.push(r.color)
        dataset.data.push(chartData.result[index])
        dataset.formattedTooltipData.label.push(ReportConversionUtil.secondsToStringFormatted(chartData.result[index], timeFormat))
        dataset.formattedLabelData.push(ReportConversionUtil.secondsToStringFormatted(chartData.result[index], timeFormat) + ' ')
    })
    dataset.borderColor = dataset.backgroundColor
    // Agrego el dataset creado
    chart.data.datasets.push(dataset)
    // nombre
    const offset = getTimeOffset()
    const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
    const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  options.title.text = fromConversion.dateString + ' ' +
      fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
    xAxes.scaleLabel.labelString = ''
    options.scales.yAxes.push(yAxes)
    options.scales.xAxes.push(xAxes)
    chart.options = options
    return chart
}

/*
{
    "trackTimestamp": {
        "value": 1624614900,
        "text": "25/06/2021 06:55:00"
    },
    "speed": {
        "value": 0,
        "text": "0.00 Km/h al Noroeste"
    },
    "direction": "276",
    "thingStateId": "60b4d293423ab80013ab6eeb",
    "stateName": {
        "es": "PARADA",
        "en": "STOPPED",
        "pt": "PARADA"
    },
    "stateId": "60b4d293423ab80013ab6eeb"
}
*/

/**
 * Procesa los datos de acuerdo a los rangos de tiempo seleccionados
 * @param data
 * @param ranges
 */
const getPercentageTimeData = (data, ranges) => {
    const result = []
    let total = 0
    ranges.forEach(() => {
        result.push(0)
    })
    let thingData
    let initTimestamp
    let endTimestamp
    let initSpeed

    data.forEach(element => {
        thingData = element.speedDetail

        if (thingData.length > 1) {
            // ordenar según timestamp
            thingData.sort((a, b) => {
                if (a.trackTimestamp.value < b.trackTimestamp.value) {
                    return -1
                }
                if (a.trackTimestamp.value > b.trackTimestamp.value) {
                    return 1
                }
                return 0
            })

            for (let i = 1; i < thingData.length; i++) {
                initSpeed = thingData[(i - 1)].speed.value
                ranges.forEach((r, index) => {
                    // RANGO "HASTA" (NO TIENE VALOR INICIAL)
                    if (r.intervalStart == null && r.intervalEnd != null) {
                        if (initSpeed <= Number(r.intervalEnd)) {
                            initTimestamp = moment.unix(thingData[(i - 1)].trackTimestamp.value)
                            endTimestamp = moment.unix(thingData[i].trackTimestamp.value)
                            result[index] += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                            total += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                        }
                    }
                    // RANGO "DESDE" (NO TIENE VALOR FINAL)
                    if (r.intervalStart != null && r.intervalEnd == null) {
                        if (initSpeed >= Number(r.intervalStart)) {
                            initTimestamp = moment.unix(thingData[(i - 1)].trackTimestamp.value)
                            endTimestamp = moment.unix(thingData[i].trackTimestamp.value)
                            result[index] += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                            total += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                        }
                    }
                    // RANGO "DESDE ... HASTA" (TIENE VALOR INICIAL Y FINAL)
                    if (r.intervalStart != null && r.intervalEnd != null) {
                        if (initSpeed >= Number(r.intervalStart) &&
                            initSpeed <= Number(r.intervalEnd)) {
                            initTimestamp = moment.unix(thingData[(i - 1)].trackTimestamp.value)
                            endTimestamp = moment.unix(thingData[i].trackTimestamp.value)
                            result[index] += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                            total += (moment.duration(endTimestamp.diff(initTimestamp))).asSeconds()
                        }
                    }
                })
            }
        }
    })

    return { result, total }
}

function getLineChartConfig () {
  return {
      chart: {
        type: 'line',
        height: '350px'
      },
      xAxis: {
        lineWidth: 1,
        type: 'datetime'
      },
      rangeSelector: {
        enabled: false
      },
      exporting: {
        enabled: true
      },
      title: {
        text: i18n.t('realSpeedReport.CHARTS.LINE_NAME'),
        align: 'left'
      },
      tooltip: {
        valueDecimals: 2,
        pointFormatter: function() {
            return '<span style="color:' + this.series.color + '">●</span> ' + this.series.name + ': <b>' + 
                this.z //velocidadReal 
                + '</b><br/>';
        }
      },
      plotOptions: {
        series: {
          showInNavigator: true
        }
      },
      series: [
        {
          name: '',
          data: [/* { x: timestamp, y: velocidadAcotada, z: velocidadReal } */]
        },
        {
          name: '',
          data: [],
        },
        {
          name: '',
          data: []
        },
        {
          name: '',
          data: []
        },
        {
          name: '',
          data: []
        }
      ]
    }
}

function processDataToSerie (thingData, lessThan, greaterThan) {
    const serie = {
        name: '',
        data: [],
        turboThreshold: 1000 // ver: https://assets.highcharts.com/errors/12/
    }
    if (thingData) {
        serie.name = thingData.thingName ? thingData.thingName : ''
        for (const serieData of thingData.speedDetail) {
            let singleValueForSerie = []
            let offset = 0
            if (serieData.trackTimestamp && serieData.trackTimestamp.value && serieData.speed) {
                let speed = serieData.speed.value;
                let realSpeed = speed;
                if(greaterThan && speed < Number(greaterThan)){
                    speed = Number(greaterThan);
                } else if(lessThan && speed > Number(lessThan)){
                    speed = Number(lessThan)
                }
                offset = (new Date().getTimezoneOffset() / -60) * 3600
                singleValueForSerie.push(((serieData.trackTimestamp.value + offset) * 1000))
                singleValueForSerie.push(speed)
                singleValueForSerie.push(realSpeed)
            }
            serie.data.push({ 
                x: singleValueForSerie[0], //timestamp
                y: singleValueForSerie[1], //velocidadAcotada
                z: singleValueForSerie[2], //velocidadReal
            })
        }
        if(serie.data.length > 1000) serie.turboThreshold = ((Math.round(serie.data.length/1000) + 1) * 1000); //solo pide la cantidad necesaria para la serie, asi no afecta al rendimiento.
    }
    return serie
}

function setMaxSpeedSerie (maxSpeed, timeFrom, timeTo, maxSpeedColor) {
  const offset = (new Date().getTimezoneOffset() / -60) * 3600;
  return {
        name: i18n.t('realSpeedReport.CHARTS.MAX_SPEED'),
        color: maxSpeedColor,
        data: [
            [(timeFrom + offset) * 1000, parseFloat(maxSpeed)], [(timeTo + offset) * 1000, parseFloat(maxSpeed)]
        ]
    }
}

async function getRealSpeedReportDeferred (things, states, from, to, dateTimeRange, customDateTimeRangeType, sinceDate, sinceTime, toDate, toTime, sector, route, timeFormat, activities, types, filterSwitch, greaterThan, lessThan) {
  const filters = {
    things,
    states,
    types,
    activities,
    sector,
    from,
    to,
    dateTimeRange,
    customDateTimeRangeType,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    route,
    timeFormat,
    filterSwitch,
    greaterThan,
    lessThan
    }
    try {
        const thingsId = things.map(thing => ({ id: thing.id, typeKey: thing.typeKey }))
        const statesId = states.map(state => state.id)
        await realSpeedReportApi.getRealSpeedReport(
            thingsId, statesId, sector.id, from, to, timeFormat, route, filters, greaterThan, lessThan)
    } catch (error) {
        console.error(error)
    }
    return null
}

const processSummaryData = (data, from, to) => {
    const result = {
        data: [],
        headers: realSpeedConstants.realSpeedHeaders,
        charts: [],
        // datos brutos para generar los gráficos de línea y de porcentaje en la ui
        speedDetails: []
    }
    const offset = getTimeOffset()
    const lang = localStorage.getItem('locale')
    let timestampConversion
    let timestamp
    if (data.length > 0) {
        result.speedDetails = data
        data.forEach(d => {
            // convierto los datos y los agrego al arreglo de datos para la tabla
            d.speedDetail.forEach(s => {
                // convierto el timestamp
                timestampConversion = ReportConversionUtil.applyTimezone(s.trackTimestamp, offset)
                timestamp = timestampConversion.dateString + ' ' + timestampConversion.timeString
                s.trackTimestamp = new ValueText(s.trackTimestamp, timestamp)
                // convierto la velocidad
                s.speed = new ValueText(Math.round((s.speed + Number.EPSILON) * 100) / 100,
                    conversionUtil.speedToStringTranslated(s.speed, s.direction, lang))
                    result.data.push({
                    id: d.thingId,
                    name: d.thingName,
                    stateName: s.stateName ? s.stateName[lang] : undefined,
                    stateId: s.stateId,
                    speed: s.speed,
                    timestamp: s.trackTimestamp
                })
            })
        })
        // si hay datos, creo la estructura del gráfico de línea
        if (result.data.length > 0) {
            // lo único que hago es cargar los labels del eje x, para evitar una sobrecarga de mapear dichos datos en la UI
            const lineChart = getReportLineChart(result.data, lang, from, to, offset)
            result.charts.push(lineChart)
        }
    }
    return result
}

/**
 * Gráfico de línea para el reporte (los datos se cargan en la UI a partir de la selección de equipos)
 * Se agregan tres datasets, uno para la línea de velocidad máxima y los demás para las líneas de velocidad de
 * los equipos.
 * @param data
 * @param lang
 * @param from
 * @param to
 * @param offset
 */
const getReportLineChart = (data, lang, from, to, offset) => {
    // ordeno los datos según el timestamp
    data = data.sort((a, b) => {
        if (a.timestamp.value < b.timestamp.value) {
            return -1
        }
        if (a.timestamp.value > b.timestamp.value) {
            return 1
        }
        return 0
    })

    const chart = realSpeedConstants.lineChart(from, to, offset)

    // dataset línea de velocidad máxima
    const datasetMaxSpeed = new Dataset()
    datasetMaxSpeed.label = i18n.t('realSpeedReport.CHARTS.MAX_SPEED', { lang })
    // se crea una línea horizontal con los puntos de los extremos
    datasetMaxSpeed.data = [
        {
            x: data[0].timestamp.value.toString(),
            y: 0
        },
        {
            x: data[(data.length - 1)].timestamp.value.toString(),
            y: 0
        }
    ]
    datasetMaxSpeed.pointRadius = 0
    datasetMaxSpeed.borderWidth = 2
    datasetMaxSpeed.backgroundColor = '#f44336'
    datasetMaxSpeed.borderColor = datasetMaxSpeed.backgroundColor
    chart.data.datasets.push(datasetMaxSpeed)

    /*
    datasets de líneas de velocidad, se precargan dos datasets con datos vacíos,
    para que el usuario desde la UI elija los datos del vehículo que quiera mostrar
    */
    const firstDataset = new Dataset()
    firstDataset.lineTension = 0.2
    firstDataset.label = i18n.t('realSpeedReport.CHARTS.equipment', { lang, args: { number: 1 } })
    firstDataset.backgroundColor = '#3F51B5'
    firstDataset.pointRadius = 0
    firstDataset.borderColor = firstDataset.backgroundColor
    const secondDataset = new Dataset()
    secondDataset.lineTension = 0.2
    secondDataset.label = i18n.t('realSpeedReport.CHARTS.equipment', { lang, args: { number: 2 } })
    secondDataset.backgroundColor = '#2196F3'
    secondDataset.pointRadius = 0
    secondDataset.borderColor = secondDataset.backgroundColor
    const thirdDataset = new Dataset()
    thirdDataset.lineTension = 0.2
    thirdDataset.label = i18n.t('realSpeedReport.CHARTS.equipment', { lang, args: { number: 3 } })
    thirdDataset.backgroundColor = '#3F51B5'
    thirdDataset.pointRadius = 0
    thirdDataset.borderColor = thirdDataset.backgroundColor
    const fourthDataset = new Dataset()
    fourthDataset.lineTension = 0.2
    fourthDataset.label = i18n.t('realSpeedReport.CHARTS.equipment', { lang, args: { number: 4 } })
    fourthDataset.backgroundColor = '#2196F3'
    fourthDataset.pointRadius = 0
    fourthDataset.borderColor = fourthDataset.backgroundColor
    chart.data.datasets.push(firstDataset)
    chart.data.datasets.push(secondDataset)
    chart.data.datasets.push(thirdDataset)
    chart.data.datasets.push(fourthDataset)

    return chart
}

export default {
    getPercentageChartByThings,
    getPercentageChartByWorkFronts,
    getDoughnutChart,
    getPercentageTimeData,
    getLineChartConfig,
    processDataToSerie,
    setMaxSpeedSerie,
    getRealSpeedReportDeferred,
    processSummaryData,
}
