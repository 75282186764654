import reportStorageApi from '@/api/report-storage.api'
import { configurationService } from '@/business/configurationService'
import realSpeedReportService from '@/business/realSpeedReportService'
import selectorService from '@/business/selectorService'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import HighchartComponent from '@/components/commons/highchart/HighchartComponent.vue'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import i18n from '@/i18n'
import { filterRulesMapped } from '@/tools/filterRules'
import {
  ISODateFromUnixTimestamp,
  dateSortFunction,
  getChildrenFromList,
  getDateTimeRange,
  selectorDateTimeValidation,
  sortFunction,
  sortFunctionFloat
} from '@/tools/functions'
import { CustomTimeRangeTypes, ReportConversionUtil, TimeRanges } from '@colven/common-domain-lib/lib'
import moment from 'moment'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import activityApi from '../../api/activity.api'
import realSpeedReportApi from '../../api/realSpeedReport.api'
import sectorApi from '../../api/sector.api'
import stateApi from '../../api/state.api'
import thingApi from '../../api/thing.api'
import thingService from '../../business/thingService'

export default {
  name: 'RealSpeedReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    AutocompleteComponent,
    HighchartComponent
  },
  props: {},
  data: () => ({
    highchartContainerHeight: Number,
    componentId: 'real-speed-report',
    tabsRealSpeed: [
      {
        id: 'tab-real-speed',
        name: i18n.t('navbar.dashboard')
      }
    ],
    currentReportKey: undefined,
    selectedTabRealSpeed: 'tab-real-speed',
    breadcrumbButtomsRealSpeed: [],
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    topCharts: [],
    bottomRightCharts: [],
    showSelector: false,
    selectorDisabled: false,
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    typeFilter: {
      id: 'type',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.harvestFront'),
      show: false,
      disabled: true,
      singleSelect: false,
      showDialog: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    stateFilter: {
      id: 'state',
      name: i18n.t('states'),
      show: false,
      disabled: true,
      singleSelect: false,
      showDialog: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    // model del selector
    selectorModel: selectorService.getSelectorModel(true),
    // backup de los datos seleccionados
    filterSwitch: true,
    timeFormat: null,
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    autoReport: false,
    autoReportMinutes: 10,
    minSpeedLimit: '',
    maxSpeedLimit: '',
    speedRules: {
      limitMinReached: (value, limit) => !value || (limit === null) || value >= Number(limit) || `${i18n.t('selector.ranges.error.minLimitReached', { limit: limit })}`,
      limitMaxReached: (value, limit) => !value || (limit === null) || value <= Number(limit) || `${i18n.t('selector.ranges.error.maxLimitReached', { limit: limit })}`,
    },
    showRangeSection: false,
    showRangeData: true,
    greaterThan: null,
    lessThan: null,
    speedPropertyName: i18n.t('headers.speed'),
    sectors: [],
    activities: [],
    types: [],
    things: [],
    states: [],
    statesId: [],
    intervalId: null,
    loadingSpinner: true,
    tableSettingDisabled: false,
    loadingTable: true,
    disableRefreshTable: true,
    maxSpeed: 0,
    unitSelector: false,
    selectorThings: [],
    selectorThingsSelected: undefined,
    nestedThings: [],
    showNoTopChartDataAvailable: false,
    showNoBottomRightChartDataAvailable: false,
    maxSpeedcolor: '#f44336',
    topChartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    bottomChartComponentModel: {
      showRangeSelector: true,
      rangeSelectorData: [
        [
          {
            intervalStart: 0,
            intervalEnd: 50,
            color: '#FF0000'
          },
          {
            intervalStart: 50,
            intervalEnd: 100,
            color: '#00FF00'
          },
          {
            intervalStart: 100,
            intervalEnd: undefined,
            color: '#0000FF'
          }
        ]
      ]
    },
    // para guardar los detalles de velocidades de las cosas seleccionadas y poder armar el gráfico de línea
    speedDetails: [],
    // modelo del primer selector de equipo para el gráfico de línea
    lineChartFirstThing: null,
    // modelo del segundo selector de equipo para el gráfico de línea
    lineChartSecondThing: null,
    // modelo del tercer selector de equipo para el gráfico de línea
    lineChartThirdThing: null,
    // modelo del cuarto selector de equipo para el gráfico de línea
    lineChartFourthThing: null,
    // spinner para el gráfico de donas
    loadingSpinnerDoughnut: false,
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.speedReport')}</b>` }],
    // ids de cosas
    thingIds: [],
    // ids de frentes de trabajo
    workFrontIds: [],
    realSpeedDetails: null,
    realSpeedDetailsKey: Math.floor(Math.random() * 10)
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    }),
    unitSelectorLabel() {
      if (!this.unitSelector) return this.$t('things')
      else return this.$t('harvestFronts.title')
    },
    /**
     * Arreglos de cosas que pueden ser seleccionadas desde los selectores para el gráfico de linea
     */
    lineChartThings() {
      return this.speedDetails.map(element => ({ id: element.thingId, name: element.thingName }))
    }
  },
  created() {
    this.breadcrumbButtomsRealSpeed.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.showSelectorMethod.bind(this)
      })
  },
  async mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    await this.getConfiguration()
    const reportKey = this.$route.query.key
    this.getData(reportKey)
    this.highchartContainerHeight = document.getElementById('row-top-charts').clientHeight;
  },
  beforeDestroy() {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    async initializeSelector() {
      this.selectorModel.filters.push(this.sectorFilter, this.activityFilter, this.typeFilter, this.workFrontFilter, this.stateFilter)

      this.selectorModel.rangeData.rules = this.speedRules;
      this.selectorModel.rangeData.showRangeSection = this.showRangeSection;
      this.selectorModel.rangeData.showRangeData = this.showRangeData;
      this.selectorModel.rangeData.greaterThan = this.greaterThan;
      this.selectorModel.rangeData.lessThan = this.lessThan;
      this.selectorModel.rangeData.minLimit = this.minSpeedLimit;
      this.selectorModel.rangeData.maxLimit = this.maxSpeedLimit;
      this.selectorModel.rangeData.propertyName = this.speedPropertyName;
      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this)
      this.activityFilter.selectAction = this.activityFilterCallback.bind(this)
      this.typeFilter.selectAction = this.typeFilterCallback.bind(this)
      this.workFrontFilter.selectAction = this.thingsFilterCallback.bind(this)
      // const statesResponse = await stateApi.getStatesFilterStructure()
      // this.stateFilter.data.cleanAndUpdate(statesResponse.data)
      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll()
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data)
      if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(sector => sector.key === 'NO_SECTOR')
        this.sectorFilter.disabled = true
        this.sectorFilter.hide = true
        this.sectorFilterCallback()
      }
    },
    async sectorFilterCallback() {
      if (this.sectorFilter.selectedData.length) {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = false
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.stateFilter.selectedData = []
        this.stateFilter.disabled = true

        const activityResponse = await activityApi.getAllForSelector(this.sectorFilter.selectedData[0].key)
        if (Array.isArray(activityResponse.data)) {
          this.activityFilter.data.cleanAndUpdate(activityResponse.data)
          if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
            this.activityFilter.selectedData = this.activityFilter.data.filter(activity => activity.key === 'NO_ACTIVITY')
            this.activityFilter.disabled = true
            this.activityFilter.hide = true
            this.typeFilter.disabled = false
            const types = await thingService.getAllThingTypesToSelector(localStorage.getItem('locale'))
            this.typeFilter.data.cleanAndUpdate(types)
            await this.typeFilterCallback()
          }
        }
      } else {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = true
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.stateFilter.selectedData = []
        this.stateFilter.disabled = true
      }
    },
    async activityFilterCallback() {
      if (this.activityFilter.selectedData.length) {
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = false
        let thingTypesResponse = null
        thingTypesResponse = await thingApi.getThingTypesByActivity(this.activityFilter.selectedData.map(activity => activity.id), this.sectorFilter.selectedData[0].key)
        this.typeFilter.data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.typeFilter.selectedData = []
        this.typeFilter.disabled = true
      }
    },
    async typeFilterCallback() {
      if (this.typeFilter.selectedData.length) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = false
        // datos para el selector de cosas
        const response = await thingApi.getWorkFrontsByTypes(this.typeFilter.selectedData.map(type => type.id), this.sectorFilter.selectedData[0].key, this.activityFilter.selectedData[0].id)
        this.workFrontFilter.data.cleanAndUpdate(response.data)
      } else {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
      }
    },
    /**
     * Callback que se ejecuta cuando cambian las equipos seleccionadas
     */
    async thingsFilterCallback() {
      if (this.workFrontFilter.selectedData.length) {
        this.stateFilter.selectedData = []
        this.stateFilter.disabled = false
        const selectedThings = []
        getChildrenFromList(this.workFrontFilter.selectedData, selectedThings)
        const selectedThingsIds = selectedThings.map(thing => thing.id)
        const stateResponse = await stateApi.getStatesByThingsFilterStructure(selectedThingsIds)
        this.stateFilter.data.cleanAndUpdate(stateResponse.data)
      } else {
        this.stateFilter.selectedData = []
        this.stateFilter.disabled = true
      }
    },
    refreshTable() {
      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      realSpeedReportApi.getRealSpeedReportDirect(
        this.things.map(thing => {
          return { id: thing.id, typeKey: thing.typeKey }
        }), this.statesId, this.timeStampFrom, this.timeStampTo, this.sectors, this.timeFormat)
        .then(response => {
          const { data } = response
          const processedSummary = realSpeedReportService.processSummaryData(data, this.timeStampFrom, this.timeStampTo)
          this.tableData.cleanAndUpdate(processedSummary.data)
          this.speedDetails.cleanAndUpdate(processedSummary.speedDetails)
          this.initTopCharts(processedSummary.charts)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.loadingTable = false
          this.setReportDetails()
          this.getPercentageChartData(this.bottomChartComponentModel.rangeSelectorData[0])
        })
    },
    resize() {
      this.$eventBus.$emit('resizeCharts');
      this.highchartContainerHeight = document.getElementById('row-top-charts').clientHeight;
      this.$refs.highchart.resizeChart(this.highchartContainerHeight);
    },
    getData(reportKey) {
      if (reportKey) {
        this.currentReportKey = reportKey
        this.selectorDisabled = false
        this.showSelector = false
        this.tableSettingDisabled = true
        this.disableRefreshTable = true
        reportStorageApi.getReport(reportKey).then(async response => {
          const { data, filters } = response.data
          const processedSummary = realSpeedReportService.processSummaryData(data, filters.from, filters.to)
          await this.setFilterData(filters)
          this.tableHeaders.cleanAndUpdate(processedSummary.headers)
          this.setSortFunctions()
          this.tableData.cleanAndUpdate(processedSummary.data)
          this.speedDetails.cleanAndUpdate(processedSummary.speedDetails)
          this.initTopCharts(processedSummary.charts)
          this.setSelectorThings()
          this.setTableFilterRules(processedSummary.headers)
          this.realSpeedDetails = realSpeedReportService.getLineChartConfig()
          this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
          this.getPercentageChartData(null)
        })
      } else {
        this.selectorDisabled = false
        this.showSelector = true
      }
    },
    setTableFilterRules(headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    selector() {
      this.showSelector = !this.showSelector
    },
    getReport(eventData) {
      this.lineChartThings.cleanAndUpdate()
      this.lineChartFirstThing = {}
      this.lineChartSecondThing = {}
      this.lineChartThirdThing = {}
      this.lineChartFourthThing = {}
      this.maxSpeed = 0
      this.maxSpeedcolor = '#f44336'
      this.things = []
      this.states = []
      this.statesId = []

      this.filterSwitch = eventData.filterSwitchValue
      let thingSelected
      if (eventData.filterSwitchValue) {
        if (this.sectorFilter.hide) {
          this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        } else {
          this.sectors.clean()
        }
        if (this.activityFilter.hide) {
          this.activities.cleanAndUpdate(this.activityFilter.selectedData)
        } else {
          this.activities.clean()
        }
        thingSelected = eventData.groupEquipmentFilterSelected
      } else {
        this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        this.activities.cleanAndUpdate(this.activityFilter.selectedData)
        this.types.cleanAndUpdate(this.typeFilter.selectedData)
        thingSelected = this.workFrontFilter.selectedData
        getChildrenFromList(this.stateFilter.selectedData, this.states)
        this.statesId = this.states.map(state => state.id)
      }

      this.unitSelector = false
      getChildrenFromList(thingSelected, this.things)

      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      this.greaterThan = eventData.rangeData.greaterThan;
      this.lessThan = eventData.rangeData.lessThan;
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.timeFormat = eventData.dateAndTimeFormat

      const that = this
      if (eventData.autoReport) {
        that.loadingTable = true
        this.disableRefreshTable = false
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        realSpeedReportApi.getRealSpeedReportDirect(
          that.things.map(thing => {
            return { id: thing.id, typeKey: thing.typeKey }
          }), that.statesId, that.timeStampFrom, that.timeStampTo, that.sectors, that.timeFormat, that.activities, that.types)
          .then(response => {
            const { data } = response
            this.realSpeedDetails = realSpeedReportService.getLineChartConfig()
            this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
            const processedSummary = realSpeedReportService.processSummaryData(data, that.timeStampFrom, that.timeStampTo)
            that.tableHeaders.cleanAndUpdate(processedSummary.headers)
            that.setSortFunctions()
            that.tableData.cleanAndUpdate(processedSummary.data)
            that.speedDetails.cleanAndUpdate(processedSummary.speedDetails)
            that.initTopCharts(processedSummary.charts)
            that.setTableFilterRules(processedSummary.headers)
            that.selectorThingsSelected = that.things[0]
            if (that.loadingSpinner) that.commitVisible({ visible: false })
            that.loadingTable = false
            that.setReportDetails()
            that.setSelectorThings()
          })
        clearInterval(that.intervalId)
        that.intervalId = setInterval(
          () => {
            const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
              this.sinceDate, this.sinceTime, this.toDate, this.toTime)
            this.timeStampFrom = conversion.tFrom
            this.timeStampTo = conversion.tTo
            if (this.loadingSpinner) this.commitVisible({ visible: true })
            this.loadingTable = true
            realSpeedReportApi.getRealSpeedReportDirect(
              this.things.map(thing => ({ id: thing.id, typeKey: thing.typeKey })),
              this.statesId, this.timeStampFrom, this.timeStampTo, this.timeStampTo, this.sectors, this.timeFormat,
              this.activities, this.types, this.greaterThan, this.lessThan)
              .then(response => {
                const { data } = response
                const processedSummary = realSpeedReportService.processSummaryData(data, this.timeStampFrom, this.timeStampTo)
                this.tableData.cleanAndUpdate(processedSummary.data)
                this.speedDetails.cleanAndUpdate(processedSummary.speedDetails)
                this.initTopCharts(processedSummary.charts)
                if (this.loadingSpinner) this.commitVisible({ visible: false })
                this.loadingTable = false
                this.getPercentageChartData(this.bottomChartComponentModel.rangeSelectorData[0])
              })
          },
          eventData.autoReportMinutes * 60 * 1000)
      } else {
        realSpeedReportService.getRealSpeedReportDeferred(this.things, this.states,
          this.timeStampFrom, this.timeStampTo, eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
          eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime, this.sectors, this.$route.path,
          this.timeFormat, this.activities, this.types, this.filterSwitch, this.greaterThan, this.lessThan)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    setReportDetails() {
      this.setDetails({
        detailData: {
          things: this.things,
          states: this.states,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          sector: this.sectors,
          activity: this.activities,
          type: this.types,
          reportName: this.reportNameForChart
        },
        dataStructure: [
          {
            detailDataProp: 'reportName',
            propTitle: this.$t('report'),
            propType: 'LIST',
            propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' }
          },
          { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
          { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
          {
            detailDataProp: 'sector',
            propTitle: this.$t('headers.sector'),
            propType: 'LIST',
            propRefs: { list: this.sectors, value: 'id', text: 'name' }
          },
          {
            detailDataProp: 'activity',
            propTitle: this.$t('headers.activity'),
            propType: 'LIST',
            propRefs: { list: this.activities, value: 'id', text: 'name' }
          },
          {
            detailDataProp: 'type',
            propTitle: this.$t('headers.thingType'),
            propType: 'LIST',
            propRefs: { list: this.types, value: 'id', text: 'name' }
          },
          {
            detailDataProp: 'things',
            propTitle: this.$t('things'),
            propType: 'LIST',
            propRefs: { list: this.things, value: 'id', text: 'name' }
          },
          {
            detailDataProp: 'states',
            propTitle: this.$t('states'),
            propType: 'LIST',
            propRefs: { list: this.states, value: 'id', text: 'name' }
          }
        ]
      })
    },
    validateSelector() {
      Vue.nextTick(() => {
        const rangeValidation = this.selectorModel.rangeData.lessThan && this.selectorModel.rangeData.greaterThan && this.selectorModel.rangeData.greaterThan > this.selectorModel.rangeData.lessThan;
        const thingsSelected = this.selectorModel.showGroupEquipmentFilter && this.selectorModel.filterSwitch.value
          ? this.selectorModel.groupEquipmentFilter.selectedData
          : this.workFrontFilter.selectedData;
        const customTimeValidation = selectorDateTimeValidation(this.selectorModel.selectedDateAndTimeRange,
          this.selectorModel.selectedDateAndTimeRangeCustomType, this.selectorModel.customDateTimeValidForm,
          this.selectorModel.sinceDate, this.selectorModel.sinceTime, this.selectorModel.toDate, this.selectorModel.toTime);
        const timeFormatSelected = this.selectorModel.selectedTimeFormat;
        this.selectorModel.disableGetReportButton = !thingsSelected.length || customTimeValidation || !timeFormatSelected || !!rangeValidation;
      })
    },
    /**
     * Inicializar los valores de los filtros guardados en los datos del reporte
     * @param {*} filterData
     */
    async setFilterData(filterData) {
      if (filterData) {
        // switch de tipo de filtrado
        this.selectorModel.filterSwitch.value = filterData.filterSwitch
        this.filterSwitch = filterData.filterSwitch

        // Filtro de sectores
        if (this.sectorFilter.data.length && filterData.sector && filterData.sector.length) {
          this.sectors.cleanAndUpdate(filterData.sector)
          this.sectorFilter.selectedData.cleanAndUpdate(filterData.sector)
          await this.sectorFilterCallback()
        }

        // filtro de actividades
        if (this.activityFilter.data.length && filterData.activities && filterData.activities.length) {
          this.activities.cleanAndUpdate(filterData.activities)
          this.activityFilter.selectedData.cleanAndUpdate(filterData.activities)
          await this.activityFilterCallback()
        }

        // filtro de tipos
        if (this.typeFilter.data.length && filterData.types && filterData.types.length) {
          this.types.cleanAndUpdate(filterData.types)
          this.typeFilter.selectedData.cleanAndUpdate(filterData.types)
          await this.typeFilterCallback()
        }

        // Filtro de frentes/equipos
        if (filterData.things && filterData.things.length) {
          this.things.cleanAndUpdate(filterData.things)
          if (this.filterSwitch) {
            this.selectorModel.groupEquipmentFilter.selectedData.cleanAndUpdate(this.things)
          } else {
            this.workFrontFilter.selectedData.cleanAndUpdate(this.things)
            await this.thingsFilterCallback()
          }
        }

        // Filtro de estados
        if (this.stateFilter.data.length && filterData.states && filterData.states.length) {
          this.states.cleanAndUpdate(filterData.states)
          this.statesId = filterData.states.map(s => s.id)
          this.stateFilter.selectedData.cleanAndUpdate(filterData.states)
        }

        // rango de tiempo
        this.timeStampFrom = filterData.from
        this.timeStampTo = filterData.to
        this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.selectorModel.sinceDate = filterData.sinceDate
        this.selectorModel.sinceTime = filterData.sinceTime
        this.selectorModel.toDate = filterData.toDate
        this.selectorModel.toTime = filterData.toTime
        this.selectorModel.selectedTimeFormat = filterData.timeFormat
        this.timeFormat = filterData.timeFormat
        this.dateAndTimeRange = filterData.dateTimeRange
        this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.sinceDate = filterData.sinceDate
        this.sinceTime = filterData.sinceTime
        this.toDate = filterData.toDate
        this.toTime = filterData.toTime
        this.selectorModel.rangeData.lessThan = filterData.lessThan
        this.selectorModel.rangeData.greaterThan = filterData.greaterThan
        this.greaterThan = filterData.greaterThan;
        this.lessThan = filterData.lessThan;
        this.setCustomDateAndTimeFilter(filterData)
        this.setReportDetails()
      }
    },
    setCustomDateAndTimeFilter(filterData) {
      if (filterData.from && filterData.to) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null
          this.selectorModel.toTime = null
        } else {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    tabSelectedRealSpeed(id) {
      this.selectedTabRealSpeed = id;
    },
    showSelectorMethod() {
      if (!this.showSelector) this.showSelector = true;
    },
    showSelectorButton() {
      return this.selectedTabRealSpeed === 'tab-real-speed';
    },
    disableSelectorButton() {
      return this.selectorDisabled;
    },
    /**
     * Setea el gráfico inicial (sin datos, para que el usuario elija las cosas)
     * @param {*} charts
     */
    initTopCharts(charts) {
      const chartComponentRef = this.$refs['realSpeedReportTopChartComponent']
      if (charts.length) {
        this.showNoTopChartDataAvailable = false
        this.topCharts.cleanAndUpdate(charts)
        // elimina la instancia anterior y vuelve a dibujar el gráfico
        if (chartComponentRef) chartComponentRef.update(this.topCharts[0])
      } else {
        this.showNoTopChartDataAvailable = true
        if (chartComponentRef) chartComponentRef.deleteAll()
      }
    },
    /**
     * Esta función se ejecuta cuando se selecciona un equipo para ser dibujado en el gráfico de línea
     */
    updateTopChartFirstDataset() {
      // primer equipo
      if (this.lineChartFirstThing) {
        const thingData = this.speedDetails.find(element => element.thingId === this.lineChartFirstThing)
        this.realSpeedDetails.series[1] = realSpeedReportService.processDataToSerie(thingData, this.lessThan, this.greaterThan)
        this.realSpeedDetails.series[1].color = '#E60000'
        this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
      }
    },
    /**
     * Esta función se ejecuta cuando se selecciona un equipo para ser dibujado en el gráfico de línea
     */
    updateTopChartSecondDataset() {
      // segundo equipo
      if (this.lineChartSecondThing) {
        const thingData = this.speedDetails.find(element => element.thingId === this.lineChartSecondThing)
        this.realSpeedDetails.series[2] = realSpeedReportService.processDataToSerie(thingData, this.lessThan, this.greaterThan)
        this.realSpeedDetails.series[2].color = '#00FF00'
        this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
      }
    },
    /**
     * Esta función se ejecuta cuando se selecciona un equipo para ser dibujado en el gráfico de línea
     */
    updateTopChartThirdDataset() {
      // tercer equipo
      if (this.lineChartThirdThing) {
        const thingData = this.speedDetails.find(element => element.thingId === this.lineChartThirdThing)
        this.realSpeedDetails.series[3] = realSpeedReportService.processDataToSerie(thingData, this.lessThan, this.greaterThan)
        this.realSpeedDetails.series[3].color = '#0000FF'
        this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
      }
    },
    
    /**
     * Esta función se ejecuta cuando se selecciona un equipo para ser dibujado en el gráfico de línea
     */
    updateTopChartFourtDataset() {
      // cuarto equipo
      if (this.lineChartFourthThing) {
        const thingData = this.speedDetails.find(element => element.thingId === this.lineChartFourthThing)
        this.realSpeedDetails.series[4] = realSpeedReportService.processDataToSerie(thingData, this.lessThan, this.greaterThan)
        this.realSpeedDetails.series[4].color = '#FF00F9'
        this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
      }
    },

    /**
     * Para actualizar el gráfico de dona del la parte inferior derecha del reporte
     * @param {*} chart
     */
    updateBottomRightCharts(chart) {
      if (chart) {
        chart.options.tooltips = {
          callbacks: {
            label(tooltipItem, data) {
              let index = tooltipItem.index
              let label = ''
              if (data.datasets && data.datasets.length && (data.datasets[0].data.length >= index) && (data.labels.length >= index)) {
                label = data.labels[index] + ': ' + ReportConversionUtil.secondsToStringFormatted(data.datasets[0].data[index], this.timeFormat.value)
              }
              return label
            }
          }
        }
        this.showNoBottomRightChartDataAvailable = false
        this.bottomRightCharts.cleanAndUpdate([chart])
        const chartComponentRef = this.$refs['realSpeedReportBottomRightChartComponent']
        if (chartComponentRef) chartComponentRef.reset(this.bottomRightCharts[0])
      } else {
        this.showNoBottomRightChartDataAvailable = true
      }
    },
    updateMaxSpeed() {
      this.realSpeedDetails.series[0] = realSpeedReportService.setMaxSpeedSerie(this.maxSpeed, this.timeStampFrom, this.timeStampTo, this.maxSpeedcolor)
      this.realSpeedDetailsKey = Math.floor(Math.random() * 10)
    },
    setSelectorThings() {
      this.selectorThings = []
      if (!this.unitSelector) {
        const things = this.speedDetails.map(element => ({ id: element.thingId, name: element.thingName }))
        this.thingIds.cleanAndUpdate(things.map(element => element.id))

        things.sort((a, b) => sortFunction(a, b))
        if (things.length > 1) {
          this.selectorThings.push({
            id: 'ALL',
            name: this.$t('all')
          })
        }
        this.selectorThings = [...this.selectorThings, ...things]
      } else {
        let workFrontIds = [...(new Set(this.speedDetails.map(element => element.workFrontId)))]
        workFrontIds = workFrontIds.filter(id => id)
        this.workFrontIds.cleanAndUpdate(workFrontIds)

        const harvestFronts = [...(
          new Set(this.speedDetails
            .filter(element => workFrontIds.includes(element.workFrontId) && element.workFrontName)
            .map(element => ({ id: element.workFrontId, name: element.workFrontName }))))]

        harvestFronts.sort((a, b) => sortFunction(a, b))
        if (harvestFronts.length > 1) {
          this.selectorThings.push({
            id: 'ALL',
            name: this.$t('all')
          })
        }
        this.selectorThings = [...this.selectorThings, ...harvestFronts]
      }
      this.selectorThingsSelected = this.selectorThings[0]
    },
    setSortFunctions() {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'timestamp').sort = (a, b) => {
        return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss')
      }
      this.tableHeaders.find(header => header.value === 'speed').sort = (a, b) => {
        return sortFunctionFloat(a, b, 'Km/h')
      }
    },
    getPercentageChartData(ranges) {
      this.loadingSpinnerDoughnut = true
      let ids
      if (this.selectorThingsSelected.id === 'ALL') {
        ids = this.selectorThings.map(element => element.id).filter(id => id !== 'ALL')
      } else {
        ids = this.selectorThings.filter(element => element.id === this.selectorThingsSelected.id).map(element => element.id).filter(id => id !== 'ALL')
      }

      let chart
      if (this.unitSelector) {
        chart = realSpeedReportService.getPercentageChartByWorkFronts(this.speedDetails, ids, ranges, this.selectorModel.selectedTimeFormat, this.timeStampFrom, this.timeStampTo)
      } else {
        chart = realSpeedReportService.getPercentageChartByThings(this.speedDetails, ids, ranges, this.selectorModel.selectedTimeFormat, this.timeStampFrom, this.timeStampTo)
      }

      this.updateBottomRightCharts(chart)
      this.loadingSpinnerDoughnut = false
    },
    /**
     * Método para clasificar las equipos según el tipo (las agrupa)
     * @param {*} things
     */
    getThingsClassifiedByType(things) {
      const typeKeysDuplicated = things.map(t => t.typeKey)
      const typeKeys = [...new Set(typeKeysDuplicated)]
      const thingsClassifiedByType = []
      typeKeys.forEach(key => {
        thingsClassifiedByType.push({
          typeKey: key,
          things: things.filter(t => t.typeKey === key)
        })
      })
      return thingsClassifiedByType
    },
    /**
     * Método para actualizar el gráfico cuando cambian los rangos
     * @param {*} event
     */
    newRanges(event) {
      configurationService.save(this.componentId, { ranges: event.ranges })
      this.getPercentageChartData(event.ranges)
    },
    async getConfiguration() {
      const config = await configurationService.get(this.componentId)
      if (config) {
        this.bottomChartComponentModel.rangeSelectorData[0] = config.data.ranges
      } else {
        this.bottomChartComponentModel.rangeSelectorData[0] = [
          {
            intervalStart: 0,
            intervalEnd: 50,
            color: '#FF0000'
          },
          {
            intervalStart: 50,
            intervalEnd: 100,
            color: '#00FF00'
          },
          {
            intervalStart: 100,
            intervalEnd: undefined,
            color: '#0000FF'
          }
        ]
      }
    },
    firstThingSelectedFunction(newValue) {
      this.lineChartFirstThing = newValue && newValue.id ? newValue.id : newValue
    },
    secondThingSelectedFunction(newValue) {
      this.lineChartSecondThing = newValue && newValue.id ? newValue.id : newValue
    },
    thirdThingSelectedFunction(newValue) {
      this.lineChartThirdThing = newValue && newValue.id ? newValue.id : newValue
    },
    fourthThingSelectedFunction(newValue) {
      this.lineChartFourthThing = newValue && newValue.id ? newValue.id : newValue
    },
    thingSelectedFunction(newValue) {
      this.selectorThingsSelected = newValue && newValue.id ? newValue : this.selectorThingsSelected
    },
    highchartExport(type) {
      if(type === 0) this.$refs.highchart.exportPDF();
      if(type === 1) this.$refs.highchart.exportPNG();
      if(type === 2) this.$refs.highchart.exportJPEG();
      if(type === 3) this.$refs.highchart.exportSVG();
    },
    highchartFullscreen() {
      this.$refs.highchart.fullscreen();
    },
    highchartPrint() {
      this.$refs.highchart.printChart();
    }
  },
  watch: {
    /**
     * Switch para cambiar de selector frente/equipos en el gráfico de torta
     */
    unitSelector() {
      this.setSelectorThings()
    },
    /**
     * Cuando se selecciona un equipo o frente en el selector
     */
    selectorThingsSelected() {
      this.getPercentageChartData(this.bottomChartComponentModel.rangeSelectorData[0])
    },
    selectorModel: {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    /**
     * Cuando cambia el selector del primer equipo del gráfico de línea
     */
    lineChartFirstThing() {
      this.updateTopChartFirstDataset()
    },
    /**
     * Cuando cambia el selector del segundo equipo del gráfico de línea
     */
    lineChartSecondThing() {
      this.updateTopChartSecondDataset()
    },
    /**
     * Cuando cambia el selector del tercer equipo del gráfico de línea
     */
    lineChartThirdThing() {
      this.updateTopChartThirdDataset()
    },
    /**
     * Cuando cambia el selector del cuarto equipo del gráfico de línea
     */
    lineChartFourthThing() {
      this.updateTopChartFourtDataset()
    }
  }
}
