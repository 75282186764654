import * as Highcharts from 'highcharts/highstock'
import * as Exporting from 'highcharts/modules/exporting'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
Exporting(Highcharts)
NoDataToDisplay(Highcharts)

export default {
  name: 'HighchartComponent',
  components: {},
  props: {
    height: Number,
    removeMenu: false,
    dataChart: {
      type: Object,
      required: false,
      default: () => {
        return {
          exporting: {
            enabled: !this.removeMenu
          },
          rangeSelector: {
            enabled: true
          },
          lang: {
            noData: 'Sin datos'
          }
        }
      }
    }
  },
  data () {
    return {
      idChart:
        'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7),
      chartInstance: null
    }
  },
  mounted () {
    // const data = this.getData(500);
    Highcharts.setOptions({
      lang: {
        loading: 'Cargando...',
        months: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ],
        weekdays: [
          'Domingo',
          'Lunes',
          'Martes',
          'Miércoles',
          'Jueves',
          'Viernes',
          'Sábado'
        ],
        shortMonths: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic'
        ],
        exportButtonTitle: 'Exportar',
        printButtonTitle: 'Importar',
        rangeSelectorFrom: 'Desde',
        rangeSelectorTo: 'Hasta',
        rangeSelectorZoom: 'Período',
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar imagen PDF',
        downloadSVG: 'Descargar imagen SVG',
        printChart: 'Imprimir',
        resetZoom: 'Reiniciar zoom',
        resetZoomTitle: 'Reiniciar zoom',
        thousandsSep: ',',
        decimalPoint: '.'
      }
    })
    this.chartInstance = Highcharts.stockChart(
      this.idChart,
      this.dataChart || {
        exporting: {
          enabled: !this.removeMenu
        },
        rangeSelector: {
          enabled: false
        },
        lang: {
          noData: 'Sin datos'
        }
      }
    )
    this.chartInstance.setSize(null, this.height);
    this.chartInstance.update({
      exporting: {
        enabled: !this.removeMenu
      }
    });
  },
  watch: {
    dataChart (value) {
      this.idChart =
        'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7)
      this.dataChart.series = value.series
    }
  },
  computed: {},
  methods: {
    resizeChart(newHeight) {
      this.height = newHeight;
      this.chartInstance.setSize(null, this.height);
    },
    exportPDF() {
      this.chartInstance.exportChart({
        type: 'application/pdf',
        filename: 'my-pdf'
      })
    },
    exportPNG() {
      this.chartInstance.exportChart({
        type: 'image/png',
        filename: 'my-png'
      })
    },
    exportJPEG() {
      this.chartInstance.exportChart({
        type: 'image/jpeg',
        filename: 'my-jpeg'
      })
    },
    exportSVG() {
      this.chartInstance.exportChart({
        type: 'image/svg+xml',
        filename: 'my-svg'
      })
    },
    fullscreen() {
      this.chartInstance.fullscreen.toggle();
    },
    printChart() {
      this.chartInstance.print();
    }
  }
}
