import { FilterType, DataType, ReportConversionUtil } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const realSpeedHeaders = [
  // 0
  {
    text: i18n.t('realSpeedReport.HEADERS.UNIT'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'name'
  },
  // 1
  {
    text: i18n.t('realSpeedReport.HEADERS.SPEED'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'speed'
  },
  // 2
  {
    text: i18n.t('realSpeedReport.HEADERS.POSITION_TIMESTAMP'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'timestamp'
  },
  // 2
  {
    text: i18n.t('realSpeedReport.HEADERS.STATE'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'stateName'
  }
]

const lineChart = (from, to, offset) => {
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' +
        fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
  return {
    id: 'realSpeedReportTopChart',
    name: i18n.t('realSpeedReport.CHARTS.LINE_NAME'),
    type: 'line',
    data: {
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: title
      },
      tooltips: {
        mode: 'nearest',
        intersect: false
      },
      hover: {
        mode: 'nearest',
        intersect: true,
        animationDuration: 0
      },
      scales: {
        xAxes: [
          {
            id: 'x-axis-0',
            gridLines: {
              display: true
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: i18n.t('realSpeedReport.CHARTS.TIMESTAMP')
            },
            ticks: {
              beginAtZero: true
            },
            stacked: false,
            type: 'time',
            time: {
              parser: 'X',
              unit: null,
              tooltipFormat: 'DD-MM-YY HH:mm:ss',
              displayFormats: {
                minute: 'DD-MM-YY HH:mm:ss'
              }
            }
          }
        ],
        yAxes: [
          {
            id: 'y-axis-0',
            gridLines: {
              display: true
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: i18n.t('realSpeedReport.CHARTS.KMH')
            },
            ticks: {
              beginAtZero: true
            },
            stacked: false
          }
        ]
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy'
          },
          zoom: {
            enabled: true,
            mode: 'x'
          }
        }
      }
    },
    animation: {
      duration: 0
    },
    responsiveAnimationDuration: 0,
    autoUpdate: false,
    allData: false,
    showDatalabels: false,
    timeDurationX: false,
    timeDurationY: false
  }
}

export default {
  realSpeedHeaders,
  lineChart
}
