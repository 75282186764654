/* eslint-disable indent */
import thingApi from '@/api/thing.api'
class ThingService {
    async getAllThingTypesToSelector(lang) {
        const response = await thingApi.getAllTypes()
        const result = []
        if (response && response.data) {
            response.data.forEach((type) => {
                result.push({
                    id: type._id,
                    name: type.name[lang],
                    key: type.key
                })
            })
        }
        return result
    }

    async getAllUserThingsByEnterprise() {
        const { data: things } = await thingApi.getAllNestedByEnterprise();
        let result = [];
        if (things && things.length > 0) {
            result = things[0].children;
        }
        return result;
    }

    async getAllUserThingMapped() {
        const things = await this.getAllUserThingsByEnterprise();
        const result = new Map();
        if (things && things.length > 0) {
            for (const thing of things) {
                result.set(thing.id, thing);
            }
        }
        return result;
    }

    /**Obtiene los frentes de trabajo anidados, pero ordenados, primero los frentes, luego los equipos que no pertenecen a ningun frente */
    async getNestedWorkFrontsOrdered() {
        let workFronts = await thingApi.getWorkFrontsNested();
        let workFrontsOrdered = []
        let thingsWithoutWorkFronts = []
        let result = [];
        if (workFronts.data) {
            workFrontsOrdered = workFronts.data.filter(wf => wf.children)
            thingsWithoutWorkFronts = workFronts.data.filter(wf => !wf.children);
            result.push(...workFrontsOrdered, ...thingsWithoutWorkFronts)
        }

        return result;

    }

    async getAllTrucksNestedByGroupAndCategories() {
        const lang = localStorage.getItem('locale') || 'es';
        let result =  (await thingApi.getAllTrucksNestedByGroupAndCategories()).data.categories;
        result.map(category => category.children.map(group => group.children.map(thing=> thing.stateName = thing.state?thing.state.name[lang] : '')))
        return result;
    }
}

export default new ThingService();
